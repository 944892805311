import { SalesGroupDetail } from "./sales-group-detail.model";
export class SalesGroup {
    SalesGroupCode: string;
    SalesGroupName: string;
    NamesInCulture: string;
    QuantityTotal: number;
    CostAmountTotal: number;
    SalesAmountTotal: number;
    ProposedSalesAmountTotal: number;
    AddonGroupCode: string;
    AddonGroupName: string;
    IsInserting: boolean;
    IsAutoNumbering: boolean;
    RowIndex: number; 
    MediaType: string;
    MediaContent: any;
    SalesGroupDetail: SalesGroupDetail[];
}