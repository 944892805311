import { TotalProductTaxesModel } from "../tax-group/total-product-taxes.model";
import { UploadDocumentModel } from "../common/document-upload.model";
import { AutoPurchaseOrderDetails } from "./auto-purchase-order-detail.model";

export class AutoPurchaseOrder {
    RowIndex: string;
    Total: number;
    AutoOrderNumber: string;
    IsAutoNumbering: boolean;
    GoDownCode: string;
    GoDownName: string;
    VendorCode: string;
    VendorName: string;
    VendorAddress: string;
    OrderDate: string;
    OrderDateValidUpto: string;
    ReferenceNumber: string;
    Attention: string;
    GrandAmount: number;
    GlobalDiscountRate: number;
    GlobalDiscountAmount: number;    
    NetAmount: number;
    Instructions: string;
    
    GlobalTax1Code: string;
    GlobalTax2Code: string;
    GlobalTax1Name: string;
    GlobalTax2Name: string;
    GlobalTax1Rate: number;
    GlobalTax1Amount: number;
    GlobalTax2Rate: number;
    GlobalTax2Amount: number;    
    DirectExps1_Amount: number;
    DirectExps1_CurrencyCode: string;
    DirectExps1_CurrencyRate: number;
    DirectExps1_NetAmount: number;
    DirectExps2_Amount: number;
    DirectExps2_CurrencyCode: string;
    DirectExps2_CurrencyRate: number;
    DirectExps2_NetAmount: number;
    DirectExps3_Amount: number;
    DirectExps3_CurrencyCode: string;
    DirectExps3_CurrencyRate: number;
    DirectExps3_NetAmount: number;
    DirectExps4_Amount: number;
    DirectExps4_CurrencyCode: string;
    DirectExps4_CurrencyRate: number;
    DirectExps4_NetAmount: number;
    DirectExps5_Amount: number;
    DirectExps5_CurrencyCode: string;
    DirectExps5_CurrencyRate: number;
    DirectExps5_NetAmount: number;
    DirectExps6_Amount: number;
    DirectExps6_CurrencyCode: string;
    DirectExps6_CurrencyRate: number;
    DirectExps6_NetAmount: number;
    DirectExps7_Amount: number;
    DirectExps7_CurrencyCode: string;
    DirectExps7_CurrencyRate: number;
    DirectExps7_NetAmount: number;
    DirectExps8_Amount: number;
    DirectExps8_CurrencyCode: string;
    DirectExps8_CurrencyRate: number;
    DirectExps8_NetAmount: number;
    DirectExps9_Amount: number;
    DirectExps9_CurrencyCode: string;
    DirectExps9_CurrencyRate: number;
    DirectExps9_NetAmount: number;
    ProductDiscountTotal: number;
    ProductTax1Total: number;
    ProductTax2Total: number;    
    OrderStatus: number;
    UpdationAllowed: boolean;
    GlobalTaxGroupID: string;
    GlobalTaxGroupName: string;
    IsGlobalTax1IncludedInPrice: boolean;
    IsGlobalTax2IncludedInPrice: boolean;
    ProductTax1IncludedInPrice: number;
    ProductTax2IncludedInPrice: number;
    Status: number;
    StatusName: string;
    VATNumber: string;
    IsTaxExempted: boolean;
    ProcessType: number;
    ProcessDocumentNumber: string;
    AutoPurchaseOrderDetail: Array<AutoPurchaseOrderDetails>;
    AutoPurchaseOrderTaxes:TotalProductTaxesModel[];
    DocumentsList:UploadDocumentModel[];
    IsInserting: boolean;
}

export class AutoPurchaseOrderListing {
    GoDownCode: string;
    GoDownName: string;
    VendorCode: string;
    VendorName: string;
    VendorAddress: string;
    OrderDate: string;
    OrderDateValidUpto: string;
    ReferenceNumber: string;
    Attention: string;
    GrandAmount: number;
    GlobalDiscountRate: number;
    GlobalDiscountAmount: number;    
    NetAmount: number;
    Instructions: string;
    
    GlobalTax1Code: string;
    GlobalTax2Code: string;
    GlobalTax1Name: string;
    GlobalTax2Name: string;
    GlobalTax1Rate: number;
    GlobalTax1Amount: number;
    GlobalTax2Rate: number;
    GlobalTax2Amount: number;    
    DirectExps1_Amount: number;
    DirectExps1_CurrencyCode: string;
    DirectExps1_CurrencyRate: number;
    DirectExps1_NetAmount: number;
    DirectExps2_Amount: number;
    DirectExps2_CurrencyCode: string;
    DirectExps2_CurrencyRate: number;
    DirectExps2_NetAmount: number;
    DirectExps3_Amount: number;
    DirectExps3_CurrencyCode: string;
    DirectExps3_CurrencyRate: number;
    DirectExps3_NetAmount: number;
    DirectExps4_Amount: number;
    DirectExps4_CurrencyCode: string;
    DirectExps4_CurrencyRate: number;
    DirectExps4_NetAmount: number;
    DirectExps5_Amount: number;
    DirectExps5_CurrencyCode: string;
    DirectExps5_CurrencyRate: number;
    DirectExps5_NetAmount: number;
    DirectExps6_Amount: number;
    DirectExps6_CurrencyCode: string;
    DirectExps6_CurrencyRate: number;
    DirectExps6_NetAmount: number;
    DirectExps7_Amount: number;
    DirectExps7_CurrencyCode: string;
    DirectExps7_CurrencyRate: number;
    DirectExps7_NetAmount: number;
    DirectExps8_Amount: number;
    DirectExps8_CurrencyCode: string;
    DirectExps8_CurrencyRate: number;
    DirectExps8_NetAmount: number;
    DirectExps9_Amount: number;
    DirectExps9_CurrencyCode: string;
    DirectExps9_CurrencyRate: number;
    DirectExps9_NetAmount: number;
    ProductDiscountTotal: number;
    ProductTax1Total: number;
    ProductTax2Total: number;    
    OrderStatus: number;
    UpdationAllowed: boolean;
    GlobalTaxGroupID: string;
    GlobalTaxGroupName: string;
    IsGlobalTax1IncludedInPrice: boolean;
    IsGlobalTax2IncludedInPrice: boolean;
    ProductTax1IncludedInPrice: number;
    ProductTax2IncludedInPrice: number;
    Status: number;
    StatusName: string;
    VATNumber: string;
    IsTaxExempted: boolean;
    ProcessType: number;

    IsVendorSelected: boolean;
    IsProductSelected: boolean;
    IsSalesAgentSelected: boolean;
    IsGodownSelected: boolean;
    DateFrom: any;
    DateTo: any;
    ProductCode: string;
    ExportOption: string;
    ProductName: string;
    SearchProductText: string;
    SearchType: Boolean;
}