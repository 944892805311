﻿import { CodeNameSearchModel } from "../common/code-name-search.model";

export class TaxesPostingSetup {
    TaxCode: string;
    Name: string;
    AccountCode: string;
    AccountName: string;
    AnalyticTagCode: string;
    AnalyticTagName: string;
    TaxType: number;
    AnalyticTagsList:Array<CodeNameSearchModel>
}