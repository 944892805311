import { EmployeeDetail } from "../EmployeeDetail/employee-detail.model";
import { SalaryAllowance } from "./salary-allowance.model";
import { SalaryDeduction } from "./salary-deduction.model";

export class Payroll {
    EmployeeSalaryCode: string = '';
    EmployeeCode: string = '';
    DepartmentName: string = '';
    EmployeeName: string = '';
    DesignationName: string = '';
    BasicSalary: number = 0;
    TotalDeduction: number = 0;
    TotalAllowance: number = 0;
    GrossSalary: number = 0;
    NetSalary: number = 0;
    ExcludeSalary: number = 0;
    PayDate: string = '';
    SalaryFrom: string = '';
    SalaryTo: string = '';
    ExecutionMonth: number = 0;
    ExecutionYear: number = 0;
    PostedToAccount: boolean = false;
    LoanInclude: boolean = false;
    Absent: boolean = false;
    Overtime: boolean = false;
    Awards: boolean = false;
    Monthly: boolean = true;
    Once: boolean = false;
    Annual: boolean = false;
    Reimbursements: boolean = false;
    Expenses: boolean = false;
    TicketOrder: boolean = false;
    EducationFund: boolean = false;
    EOSB: boolean = false;
    TravelExpenses: boolean = false;
    SalaryBy: number = 1;
    PaymentMethod: number = 1;
    TransactionNumber: number = 0;
    PayrollEmployees: EmployeeDetail[] = new Array<EmployeeDetail>();
    EmployeeSalaryAllowances: SalaryAllowance[] = new Array<SalaryAllowance>();
    EmployeeSalaryDeductions: SalaryDeduction[] = new Array<SalaryDeduction>();
}
