export class LoyaltyTransaction {

    constructor(pageNumber = "") {
        this.ExportOption = '0';
        this.Skip = 1;
        this.Next = 100;
        this.FilterText = "";
        this.PageNumber = pageNumber ? pageNumber : '2988';
    }

    RowIndex: number;
    Total: number;
    IsAutoNumbering: boolean;
    IsInserting: boolean;
    LoyaltyTransactionCode: number;
    LoyaltyCode: string;
    LoyaltyName: string;
    BuyerCode: string;
    BuyerName: string;
    TransactionDate: string;
    Status: number;
    TransactionReference: string;
    Points: number;
    TotalPoints: number;
    ProductBarCode: string;
    ProductBarCodeName: string;
    Amount: number;
    TotalAmount: number;
    TransactionStatus: string;


    Skip: number;
    Next: number;
    FilterText: string;
    CultureCode: string;
    ExportOption: string;
    PageNumber: string;
}
/**
 * For Posting Data in Transaction Tabel use Following model
 */
export class LoyaltyTransactionModel {

    LoyaltyTransactionCode: number;
    LoyaltyCode: string;
    LoyaltyName: string;
    BuyerCode: string;
    BuyerName: string;
    TransactionDate: string;
    Status: number;
    TransactionReference: string;
    Points: number;
    TotalPoints: number;
    ProductBarCode: string;
    ProductBarCodeName: string;
    Amount: number;
    TotalAmount: number;
}