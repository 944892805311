export class BuyerAddress {
  BuyerCode: string;
  AddressType: string;
  POBox: string;
  City: string;
  CountryCode: string;
  CountryName: string;
  ZIPCode: string;
  Phone: string;
  Fax: string;
  Address: string;
}
