export class EmployeeContactDetail {
    Address: string = '';
    CityCode: string = '';
    CityName: string = '';
    AreaCode: string = '';
    AreaName: string = '';
    PhoneNo: string = '';
    MobileNo: string = '';
    EmailAddress: string = '';
    EmergencyName: string = '';
    EmergencyRelation: string = '';
    EmergencyPhoneNo: string = '';
    EmergencyAddress: string = '';
    EmergencyCityCode: string = '';
    EmergencyCityName: string = '';
    EmergencyAreaCode: string = '';
    EmergencyAreaName: string = '';
    EmergencyMobileNo: string = '';
}