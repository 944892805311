﻿export class AccountsOfStatmentModel {

    constructor() {

        this.AccountCodeFrom = "";
        this.AccountNameTo = "";
        this.AccountCodeTo = "";
        this.AccountNameTo = "";
        this.selectedFilterType = "2";
       
        this.IsShowSubAccountTransactions = false;
        this.IsShowUnclearedCheques = false;
        this.ShowCheque = "";
        this.ShowSubTrans = "";
        this.ShowRunBalance = "";
        this.IsCurrencyEnabled = false;
        this.CurrencyEnabled = "";
        this.CurrencyType = "1";
        this.IsShowCurrencyRate = false;
        this.CurrencyCode = "";
        this.ShowCurrencyRate = "";
        this.ComponyLogo = false;
        this.CCRNumber = false;
        this.Address = false;
        this.Email = false;
        this.Fax = false;
        this.ContactNumber = false;
        this.GeneralManagerSig = false;
        this.FinancialRefrencessig = false;
        this.FinancialMangaerSig = false;
        this.AccountantSig = false;
        this.ExportOption = '0';
        this.FilterType = '0';
        this.FilterValue = '';
        this.PageNumber = '323'
    }


    AccountCodeFrom: string;
    AccountNameFrom: string;
    AccountCodeTo: string;
    AccountNameTo: string;
    selectedFilterType: string;
    IsShowUnclearedCheques: boolean;
    IsShowRunningBalance: boolean;
    IsShowSubAccountTransactions: boolean;
    PeriodFrom: any;
    PeriodTo: any;
    ShowCheque: string;
    ShowSubTrans: string;
    ShowRunBalance: string;
    IsCurrencyEnabled: boolean;
    CurrencyEnabled: string;
    CurrencyType: string;
    CurrencyCode: string;
    IsShowCurrencyRate: boolean;
    ShowCurrencyRate: string;
    ComponyLogo: boolean;
    CCRNumber: boolean;
    Address: boolean;
    Email: boolean;
    Fax: boolean;
    ContactNumber: boolean;
    FilterType:string;
    FilterValue:string;
    GeneralManagerSig: boolean;
    FinancialRefrencessig: boolean;
    FinancialMangaerSig: boolean;
    AccountantSig: boolean;
    ExportOption: string;
    PageNumber: string;
}