export class VendorAddress {
  VendorCode: string;
  AddressType: string;
  POBox: string;
  City: string;
  CountryCode: string;
  ZIPCode: string;
  Phone: string;
  Fax: string;
  Address: string;
}
