export enum Graphs{
    SalesChart = 401,
    PurchasePieChart=402,
    SalesPieChart=403,
    CurrentStockProductGroups=404,
    PurchaseVsSalesChart=405,
    TopSalesProductChart=406,
    SalesInvoicesCounts=407,
    PurchaseInvoicesCounts=408,
    TopVendors=409,
    TopBuyers=410,
    Collections=411,
    ProductsList = 412,
    NotSoldProductsList = 413,

    //#region 
    FinanceBoxes = 201,
    ExpensesChart = 202,
    TopExpensesAccounts = 203,
    ExpenseVsRevenue = 204,
    TopEarningAccounts = 205,
    InvoiceBoxes = 206,
    //#endregion

}