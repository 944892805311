import { TaxesModel } from "../../TaxSetup/taxes.model";
import { SalesInvoiceDetailsHistory } from "./sales-invoice-details-history";

export class SalesInvoiceHistory {
    SalesBillID: string;
    BillNumber: string;
    FiscalSpanID: number;
    BillDate: string;
    BillType: number;
    PaymentType: number;
    GoDownCode: string;
    GoDownName: string;
    BuyerCode: string;
    BuyerName: string;
    BuyerVAT: string;
    BuyerCCR: string;
    BuyerPhone: string;
    CardDetails: string;
    SalesAgentCode: string;
    SalesmanName: string;
    GrandAmount: number;
    GlobalDiscountRate: number;
    GlobalDiscountAmount: number;
    GlobalTax1Rate: number;
    GlobalTax1Amount: number;
    GlobalTax2Rate: number;
    GlobalTax2Amount: number;
    SurplusChargesAmount: number;
    NetAmount: number;
    AdvancePaidInCash: number;
    CounterCode: string;
    RoundOffAmount: number;
    RoundOffDifference: number;
    ECommUserID: number;
    ReturnedBillNumber: string;
    ReturnedFiscalSpanID: number;
    ReturnedBillDate: string;
    IsProcessed: boolean;
    IsUploaded: boolean;
    StartTime: string;
    EndTime: string;
    TagNo: string;
    CashTender: number;
    CreditAmount: number;
    GlobalTaxGroupID: string;
    GlobalTaxGroupName: string;
    IsGlobalTax1IncludedInPrice: boolean;
    IsGlobalTax2IncludedInPrice: boolean;
    BillTime: string;
    PaymentTypeName: string;
    DeliveryType: string;
    DeliveryTypeNote: string;
    ReferenceNumber: string;
    BillDetails: SalesInvoiceDetailsHistory[];
    GlobalTax1Code: string;
    GlobalTax1Name: string;
    GlobalTax2Code: string;
    GlobalTax2Name: string;
    BillTotalProductTaxes: TaxesModel[];
    BillingAddress: string;
    PhoneNumber: string;
    DeliveryStatus: number;    
    IsReturnApproved: number;
    ShippingCharges: number;
    StoreConnectionString: string;
    CompanyID: string;
    CompanyName: string;
    StoreCode: string;
    StoreName: string;
    FormattedDate: string;
    RiderCode: string;
    RiderName: string;
    ShipmentCode: string;
    IsPickupFromStore: boolean;
    APIToken: string;
}