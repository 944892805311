import { BuyerAddress } from "./buyeraddress.model";
import { BuyerNames } from "./buyersname.model";

export class Buyer {
    BuyerCode: string;
    BuyerName: string;
    BuyerGroupCode: string;
    SecurityAmount?: number;
    CreditLimit?: number;
    CreditLimitUsed?: number;
    CreditDays?: number;
    DiscountLimit?: number;
    DiscountOnPayment?: number;
    SalesAgentCode: string;
    SalesAgentName: string;
    SalesRegionCode: string;
    SalesRegionName: string;
    VATNumber: string;
    IsActive: boolean;
    IsSelected: boolean;
    AccountCode: string;
    AccountName: string;
    IsTaxExempted: boolean;
    CCRNumber: string;
    PrimaryPhone: string;
    Email: string;
    IsInserting: boolean;
    IsAutoNumbering: boolean;
    CallFromInvoice: boolean;
    RowIndex?: number;
    IsDiscountEnabled: boolean;
    MultiNames: string;    
    AddressTable?: Array<BuyerAddress>;
    NamesTable?: Array<BuyerNames>;
    TotalInvoices?: number;
    TotalReturns?: number;
    CurrentBalance?: number;
    PaymentTermCode: string;
    PaymentTermName: string;
    CurrencyCode: string;
    CurrencyName: string;
    CashTransactionCount: number;
    CreditTransactionCount: number;
    NetAmount: number;
    AmountReturned: number;
}
export class BuyerImport{
    Srno: number;
    BuyerCode: string;
    BuyerNameArabic: string;
    BuyerNameEnglish: string;
    BuyerPhone: string;
    BuyerEmail: string;
    BuyerNTN: string;
    BuyerCRNumber: string;
    // IsActive: boolean;
    LetOffTax: number;
    SalesAgentCode: string;
    // SalesRegionCode: string;
    AccountCode: string;
    Address: string;
}