export class ProductColor {
    IsSelected: boolean
    RowNum: number;
    Total: number;
    ProductsColorCode: string;
    Name: string;
    ColorCode: string;
    ColorName: string;
    MultiNames: string;
    IsInserting: boolean;
    OrderSequence: number;
    IsAutoNumbering: boolean;
    Status: number;
    Disable: boolean;
    constructor() {
        this.IsSelected = false;
    }
}
