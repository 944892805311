import { SaleReturnDetails } from "./sale-return-details.model";
import { TransactionPosting } from "../common/getjsonfrom-controls-service-model";
import { TotalProductTaxesModel } from "../tax-group/total-product-taxes.model";
import { UploadDocumentModel } from "../common/document-upload.model";

export class SaleReturns {
    DocumentNumber: string;
    IsAutoNumbering: boolean;
    IsInserting: boolean;
    RowIndex?: number;
    FiscalSpanID: number;
    DocumentDate: string;
    GoDownCode: string;
    SalesAgentCode: string;
    BuyerCode: string;
    BuyerName: string;
    BuyerAddress: string;
    InvoiceLinked?: boolean;
    InvoiceNumber: string;
    PreviousFiscalSpanID: number;
    InvoiceDate: string;
    AllowedDaysForReturn: number;
    PaymentType?: number;
    GrandAmount?: number;
    GlobalDiscountAmount?: number;
    NetAmount?: number;
    AddlChargesAmount: number;
    Instructions: string;
    GlobalTax1Amount?: number;
    GlobalTax2Amount?: number;
    ProductDiscountTotal?: number;
    ProductTax1Total?: number;
    ProductTax2Total?: number;
    ProductTax3Total?: number;
    ProductTax4Total?: number;
    TotalCostAmount: number;
    TotalInventoryAmount: number;
    TotalServiceAmount: number;
    TotalInventorySales: number;
    TotalServiceSales: number;
    TotalInventoryCost: number;
    TotalServiceCost: number;
    TotalInventoryDiscount: number;
    TotalServiceDiscount: number;
    TotalInventoryProductTax: number;
    TotalServiceProductTax: number;
    TransactionNumber?: number;
    PostedToAccount?: boolean;
    IsAccountLinked: boolean;
    AdjustedInvoiceNumber: string;
    AdjustedInvoiceFiscalSpanID: number;
    AdjustedInvoiceAmount: number;
    PrePostedDocumentDate: string;
    CounterCode: string;
    RoundOffAmount: number;
    RoundOffDifference: number;
    UpdationAllowed?: boolean;
    OperatingUserID: number;
    GlobalTaxGroupID: string;
    GlobalTax1Code: string;
    GlobalTax2Code: string;
    GlobalTax1Rate?: number;
    IsGlobalTax1IncludedInPrice?: boolean;
    GlobalTax1IncludedInInventory?: number;
    GlobalTax1IncludedInService?: number;
    GlobalTax2Rate?: number;
    IsGlobalTax2IncludedInPrice?: boolean;
    GlobalTax2IncludedInInventory: number;
    GlobalTax2IncludedInService: number;

    ProductTax1IncludedInInventory: number;
    ProductTax1IncludedInService: number;
    ProductTax2IncludedInInventory: number;
    ProductTax2IncludedInService: number;
    InvoiceFiscalSpanID: number;
    CreditDays: string;
    CreditDateUpto: string;
    GoDownName: string;
    GlobalTaxGroupName: string;
    VATNumber: string;
    ReferenceNumber: string;
    
    SalesReturnDetails: SaleReturnDetails[] = [];
    TransactionPosting: TransactionPosting[];
    IsBatchSerialLinked: boolean
    SalesReturnTaxes:TotalProductTaxesModel[];
    DocumentsList:UploadDocumentModel[]
}
