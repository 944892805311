﻿export class AccountMaster {    
    AccountCode: string;
    AccountName: string;
    NamesInCulture: string;
    DefaultCurrency: string;
    AccountType: number;
    PLBSClassCode: string;
    CodeLevel: number;
    SubAccountSupport: boolean;
    ParentAccountCode: string;
    ParentAccountName:string;
    CostCenterSupport: boolean;
    DefaultCostCenterCode: string;
    DefaultCostCenterName: string;
    IsInserting: boolean;
    IsAutoNumbering: boolean;
    IsChecked: boolean;
    ListAccountMaster: AccountMaster[];
}



export class COAImportMaster { 
    Srno: number;
    AccountNameInEnglish: string;
    AccountNameInArabic: string;
    AccountCode: string;
    CodeLevel: string;
    ParentAccountCode: string;
    AccountType: string;
    PLBSClassCode: string;
    IsParentCodeNotExist: boolean;
}

export class COAMaster {    
    AccountCode: string;
    AccountType: number;
    PLBSClassCode: string;
    CodeLevel: number;
    SubAccountSupport: boolean;
    ParentAccountCode: string;
    CostCenterSupport: boolean;
    DefaultCurrency: string;
    MultiCurrencySupport: boolean;
    LastSubAccountNumber: number;
    DefaultCostCenterCode: string;
}

export class COAMasterName {    
    AccountCode: string;
    AccountName: string;
    CultureCode: string;
}

export class COAImportSave{
    COAData: COAMaster[];
    COAName:COAMasterName[];
}