import { TransactionDetailGridModel } from "./transaction-detail-grid.model";

export class TransactionDetailModel {    

    constructor() {
        this.DetailGridList = new Array<TransactionDetailGridModel>();        
    }
    TransactionNumber: number;
    TransactionDate: string;
    TransactionType: string;
    DocumentNumber: string;
    CreditTotal: number;
    DebitTotal: number;
    DetailGridList: Array<TransactionDetailGridModel>;
}