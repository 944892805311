﻿import { StockInternalUseDetail } from "./stockinternalusedetail.model";
import { TransactionPosting } from "../common/getjsonfrom-controls-service-model";

export class StockInternalUse {
    IsAutoNumbering: boolean;
    IsInserting: boolean;
    RowIndex: number;
    DocumentNumber: string;
    DocumentDate: string;
    GoDownCode: string;
    GoDownName: string;
    VendorCode: string;
    ReferenceNumber: string;
    EnteredBy: string;
    NoOfProducts: number;
    GrandAmount: number;   
    AccountCode: string;    
    UpdationAllowed: boolean;
    StockInternalUseDetail: StockInternalUseDetail[];

    IsAccountLinked: boolean;
    TransactionNumber: number;
    TransactionPosting: TransactionPosting[];
    IsBatchSerialLinked: boolean
}