import { SaleInvoiceDetails } from "./sale-invoice-details.model";
import { TransactionPosting } from "../common/getjsonfrom-controls-service-model";
import { TotalProductTaxesModel } from "../tax-group/total-product-taxes.model";
import { UploadDocumentModel } from "../common/document-upload.model";
import { LoyaltyTransactionModel } from "../LoyaltyManagement/loyalty-transaction.model";

export class SaleInvoice {
    constructor() {
        this.BalancePoints = 0;
        this.EarnedPoints = 0;
    }
    InvoiceNumber: string;
    IsAutoNumbering: boolean;
    IsInserting: boolean;
    RowIndex?: number;
    Total?: number;
    FiscalSpanID: number;
    InvoiceDate: string;
    RecordTimeStamp: string;
    GoDownCode: string; // Used as Godown (in normal sale bill) or Source Godown (In branch to branch sale) .
    GoDownName: string; 
    GoDownInCode: string; // Used as destination Godown in branch to branch sale.
    GoDownInName: string;
    SalesAgentCode: string;
    SalesAgentName: string;
    BuyerCode: string;
    BuyerName: string;
    BuyerAddress: string;
    OrderNumber: string;
    OrderDate: string;
    PaymentType: number;
    CardDetails: string;
    CreditDays: number;
    CreditDateUpto: string;
    ReferenceNumber: string;
    Attention: string;
    GlobalTax1Code: string;
    GlobalTax2Code: string;
    GlobalTax1Name: string;
    GlobalTax2Name: string;
    GrandAmount: number;
    GlobalDiscountRate: number;
    GlobalDiscountAmount: number;
    NetAmount: number;
    AdvanceAmount: number;
    AmountCollected: number;
    AmountReturned: number;
    Instructions: any;
    GlobalTax1Rate: number;
    GlobalTax1Amount: number;
    GlobalTax2Rate: number;
    GlobalTax2Amount: number;
    GlobalTax3Rate: number;
    GlobalTax3Amount: number;
    GlobalTax4Rate: number;
    GlobalTax4Amount: number;
    ChargesTotal: number;
    AddlCharges1_Amount: number;
    AddlCharges1_CurrencyCode: string;
    AddlCharges1_CurrencyRate: number;
    AddlCharges1_NetAmount: number;
    AddlCharges1_FieldName: string;
    AddlCharges2_Amount: number;
    AddlCharges2_CurrencyCode: string;
    AddlCharges2_CurrencyRate: number;
    AddlCharges2_NetAmount: number;
    AddlCharges2_FieldName: string;
    AddlCharges3_Amount: number;
    AddlCharges3_CurrencyCode: string;
    AddlCharges3_CurrencyRate: number;
    AddlCharges3_NetAmount: number;
    AddlCharges3_FieldName: string;
    AddlCharges4_Amount: number;
    AddlCharges4_CurrencyCode: string;
    AddlCharges4_CurrencyRate: number;
    AddlCharges4_NetAmount: number;
    AddlCharges4_FieldName: string;
    AddlCharges5_Amount: number;
    AddlCharges5_CurrencyCode: string;
    AddlCharges5_CurrencyRate: number;
    AddlCharges5_NetAmount: number;
    AddlCharges5_FieldName: string;
    AddlCharges6_Amount: number;
    AddlCharges6_CurrencyCode: string;
    AddlCharges6_CurrencyRate: number;
    AddlCharges6_NetAmount: number;
    AddlCharges6_FieldName: string;
    AddlCharges7_Amount: number;
    AddlCharges7_CurrencyCode: string;
    AddlCharges7_CurrencyRate: number;
    AddlCharges7_NetAmount: number;
    AddlCharges7_FieldName: string;
    AddlCharges8_Amount: number;
    AddlCharges8_CurrencyCode: string;
    AddlCharges8_CurrencyRate: number;
    AddlCharges8_NetAmount: number;
    AddlCharges8_FieldName: string;
    AddlCharges9_Amount: number;
    AddlCharges9_CurrencyCode: string;
    AddlCharges9_CurrencyRate: number;
    AddlCharges9_NetAmount: number;
    AddlCharges9_FieldName: string;
    ProductDiscountTotal: number;
    ProductTax1Total: number;
    ProductTax2Total: number;
    ProductTax3Total: number;
    ProductTax4Total: number;
    TotalCostAmount: number;
    TotalInventoryAmount: number;
    TotalServiceAmount: number;
    TotalInventorySales: number;
    TotalServiceSales: number;
    TotalInventoryCost: number;
    TotalServiceCost: number;
    TotalInventoryDiscount: number;
    TotalServiceDiscount: number;
    TotalInventoryProductTax: number;
    TotalServiceProductTax: number;
    TransactionNumber: number;
    PostedToAccount: boolean;
    IsAccountLinked: boolean;
    InvoicePrinted: boolean;
    ProcessedViaOrder: boolean;
    PrePostedInvoiceDate: string;
    CounterCode: string;
    RoundOffAmount: number;
    RoundOffDifference: number;
    PageID: number;
    UpdationAllowed: boolean;
    OperatingUserID: number;
    GlobalTaxGroupID: string;
    GlobalTaxGroupName: string;
    IsGlobalTax1IncludedInPrice: boolean;
    GlobalTax1IncludedInInventory: number;
    GlobalTax1IncludedInService: number;
    IsGlobalTax2IncludedInPrice: boolean;
    GlobalTax2IncludedInInventory: number;
    GlobalTax2IncludedInService: number;
    IsGlobalTax3IncludedInPrice: boolean;
    GlobalTax3IncludedInInventory: number;
    GlobalTax3IncludedInService: number;
    IsGlobalTax4IncludedInPrice: boolean;
    GlobalTax4IncludedInInventory: number;
    GlobalTax4IncludedInService: number;
    ProductTax1IncludedInInventory: number;
    ProductTax1IncludedInService: number;
    ProductTax2IncludedInInventory: number;
    ProductTax2IncludedInService: number;
    ProductTax3IncludedInInventory: number;
    ProductTax3IncludedInService: number;
    ProductTax4IncludedInInventory: number;
    ProductTax4IncludedInService: number;
    VATNumber: string;
    IsTaxExempted: boolean;
    CardCode: string;
    CardName: string;
    BankCode: string;
    BankName: string;
    LoyaltyCode: string;
    LoyaltyName: string;
    LoyaltyCardCode: number;
    BalancePoints: number;
    EarnedPoints: number;
    RedeemPoints: number;
    CardChargeType: number;
    CardCharges: number;
    CardChargeRate: number;
    CardChargeAmount: number;
    OrgCardChargeAmount: number;
    CardChargeVATRate: number;
    CardChargeVATAmount: number;
    OrgCardChargeVATAmount: number;
    ProcessDocumentNumber: string;
    ProcessType: number;
    RewardType: number;
    PriceListCode: string;
    ChannelPriceName: string;
    saleInvoiceDetails: SaleInvoiceDetails[];
    TransactionPosting: TransactionPosting[];
    IsBatchSerialLinked: boolean
    SalesInvoiceTaxes: TotalProductTaxesModel[];
    DocumentsList: UploadDocumentModel[];
    LoyaltyTransactionList: LoyaltyTransactionModel[];
    PaymentTypeName: string;
    GDNNumber: string;
    CreditAlert: number;
}
