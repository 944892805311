import { SaleInvoice } from "../SaleInvoice/sale-invoice.model";

export class SalesAgentCommissionModel {

    constructor() {

        this.StartDate = "";
        this.EndDate = "";
        this.IsSalesAgentSelected = false;
        this.IsGodownSelected = false;
        this.SalesAgentCode = "";
        this.SalesAgentName = "";
        this.GodownCode = "";
        this.GodownName = "";
        this.CalculateOnCollection = 0;
        this.ShowSaleDetail = 0;
        this.ShowCommissionDetail = 0;
        this.ShowTargetDetail = 0;
        this.ReportBasedOn = '1';
        this.ExportOption = '0';

        this.TotalSales = 0;
        this.TotalReturns = 0;
        this.NetSales = 0;
        this.TotalCash = 0;
        this.TotalCollect = 0;
        this.TotalPending = 0;
        this.TotalSaleComAmt = 0;
        this.ShowSale = 0;
        this.ShowRefund = 0;
        this.ShowDiscount = 0;
        this.ShowTax = 0;
        this.SalesInvoices = new Array<SaleInvoice>();  
    }

    ExportOption: string;
    ReportBasedOn: string;
    StartDate: any;
    EndDate: any;
    IsSalesAgentSelected: boolean;
    SalesAgentCode: string;
    SalesAgentName: string;
    IsGodownSelected: boolean;
    GodownCode: string;
    GodownName: string;
    CalculateOnCollection: number;
    ShowSaleDetail: number;
    ShowCommissionDetail: number;
    ShowTargetDetail: number;
    ShowSale: number;
    ShowRefund: number;
    ShowDiscount: number;
    ShowTax: number;

    TotalSales: number;
    TotalReturns: number;
    NetSales: number;
    TotalCash: number;
    TotalCollect: number;
    TotalPending: number;
    TotalSaleComAmt: number;

    SalesInvoices: SaleInvoice[];
}
