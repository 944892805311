export class CodeSetup {    
    GodownCodeSetupID: number;
    FiscalSpanID: number;
    AutoCode: boolean;
    Level1: string;
    Level2: string;
    Level3: string;
    Level4: string;
    Level5: string;
    Level2Enable: boolean;
    Level3Enable: boolean;
    Level4Enable: boolean;
    Level5Enable: boolean;
    Level1Sample: string;
    Level2Sample: string;
    Level3Sample: string;
    Level4Sample: string;
    Level5Sample: string;
    UserID: number;   
    GodownExist: boolean;
}
