import { VendorAddress } from "./vendoraddress.model";
import { VendorNames } from "./vendornames.model";

export class Vendor {
    constructor() {
        this.TotalInvoices = 0;
        this.TotalReturns = 0;
        this.TotalDue = 0;
    }
    CallFromInvoice: boolean = false;
    VendorCode: string;
    VendorName: string;
    SecurityAmount?: number;
    CreditLimit?: number;
    CreditDays?: number;
    DiscountPercentage?: number;
    VATNumber: string;
    IsActive: boolean;
    AccountCode: string;
    AccountName: string;
    IsTaxExempted: boolean;
    CCRNumber: string;
    PrimaryPhone: string;
    Email: string;
    IsInserting: boolean;
    IsSelected: boolean;
    IsAutoNumbering: boolean = false;
    MultiNames: string;
    AddressTable?: Array<VendorAddress>;
    NamesTable?: Array<VendorNames>;

    TotalInvoices?: number;
    TotalReturns?: number;
    TotalDue?: number;

    CashTransactionCount: number;
    CreditTransactionCount: number;
    NetAmount: number;
    CurrencyCode: string;
    CurrencyName: string;

    AutoProjectionDay: number;
}
export class VendorImport{
    Srno: number;
    VendorCode: string;
    VendorNameArabic: string;
    VendorNameEnglish: string;
    VendorPhone: string;
    VendorEmail: string;
    VendorNTN: string;
    VendorCRNumber: string;
    // IsActive: boolean;
    LetOffTax: number;
    AccountCode: string;
    Address: string;
}
