export class ProductSize {
    IsSelected:boolean;
    RowNum: number;
    Total: number;
    ProductsSizeCode: string;
    Name: string;
    MultiNames: string;
    IsInserting: boolean;
    OrderSequence: number;
    IsAutoNumbering: boolean;
    Status: number;
    Disable: boolean;
    /**
     *
     */
    constructor() {
        this.IsSelected = false;
        
    }
}
