export class  ChequeSearchModel {
    DateFrom: string;
    DateTo: string;
    AccountCodeFrom: string;
    BankName: string;
    DocumentNumber: string;
    ChequeType: number;
    ProcessType: number;
    CreatedBy:string;
    SearchKey:string;
}