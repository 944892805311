import { EmployeeContactDetail } from "../EmployeeContactDetail/employee-contact-detail";
import { FileModel } from "../FileModel/file-model.model";
export class Employee {
    IsAutoNumbering: boolean;
    Action: string = 'Save';
    Name: string = '';
    NamesInCulture: string;
    EmployeeCode: string = '';
    FatherName: string = '';
    Gender: string = '';
    CNIC: string = '';
    CNICExpiryDate: string = '';
    DOB: string = '';
    BirthPlace: string = '';
    AnyDisability: boolean = false;
    BloodGroup: string = '';
    MaritalStatus: number = 1;
    DepartmentCode: string = '';
    DepartmentName: string = '';
    DesignationCode: string = '';
    DesignationName: string = '';
    JobType: string = '';
    JoiningDate: string = '';
    EmployeeStatus: number = 1;
    PayGroupCode: string = '';
    PayGroupName: string = '';
    BranchCode: string = '';
    BranchName: string = '';
    GradeCode: string = '';
    GradeName: string = '';
    ProfileImg: string = ''
    NationalityCode: string = '';
    NationalityName: string = '';
    ReligionCode: string = '';
    ReligionName: string = '';
    SpouseName: string = '';
    NoOfChildren: number = 0;
    Education: string = '';
    Experience: number = 0;
    ReportToEmployeeCode: string = '';
    ReportToEmployeeName: string = '';
    ReportToDepartmentCode: string = '';
    ReportToDepartmentName: string = '';
    MediaContent: string = '';
    ContentType: string = '';
    EmployeeType: number = 1;
    RowIndex: number;
    ContactDetail: EmployeeContactDetail = new EmployeeContactDetail();
    DocumentsList: FileModel[] = new Array<FileModel>();
}
