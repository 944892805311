import { StockBatchSerials } from "../common/getjsonfrom-controls-service-model";

export class SaleReturnDetails {
    ReturnDetailsID: string;
    DocumentNumber: string;
    FiscalSpanID: number;
    DocumentDate: string;
    GoDownCode: string;
    SerialNumber: number;
    ProductType: number;
    ProductCode: string;
    ProductName: string;
    Quantity: number;
    UnitType: number;
    UnitCode: string;
    UnitFragment: number;
    NetQuantity: number;
    Weight: number;
    NetWeight: number;
    Price: number;
    PriceType: number;
    CurrencyCode: string;
    CurrencyRate: number;
    Amount: number;
    DiscountRate: number;
    DiscountAmount: number;
    Tax1Amount: number;
    Tax2Amount: number;
    GrandAmount: number;
    GlobalDiscountAmount?: number;
    GlobalTax1Amount?: number;
    GlobalTax2Amount?: number;
    NetAmount?: number;
    NetPrice: number;
    AddlChargesAmount: number;
    CostPrice: number;
    CostAmount: number;
    InvoiceLinked?: boolean;
    InvoiceNumber: string;
    InvoiceDetailsID: string;
    Description: string;

    GlobalTax1Code: string;
    GlobalTax2Code: string;
    GlobalTax1Name: string = '';
    GlobalTax2Name: string = '';

    Tax1Code: string;
    Tax2Code: string;
    Tax1Name: string = '';
    Tax2Name: string = '';

    PreviousFiscalSpanID: number;
    ProductBarCode: string;
    TaxGroupID: string;
    Tax1Rate?: number;
    IsTax1IncludedInPrice: boolean;
    Tax2Rate?: number;
    IsTax2IncludedInPrice: boolean;
    GroupDataID: string;
    InvoiceFiscalSpanID: number;
    Length: number;
    Width: number;
    Height: number;
    BalanceQuantity: number;
    SubQuantity: number | null;
    ReturnedQuantity?: number;
    ReturnUnitCode: string;
    BaseUnitCode: string;
    QuantitySales: number;
    IsMeasurementRequired: boolean;
    MeasurementType: number;
    IsBatchRequired: boolean;
    IsSerialNumberRequired: boolean;
    BatchSerialDetails: StockBatchSerials[];
    SearchValues?: string[]
    GlobalTaxGroupID:string;
    GlobalTaxGroupName?: string
    ReturnAmount?: number;
    IsParentAddOn: boolean;
    AddOnGroupCode: string;
    ParentReturnDetailsID: string;

    BuyerCode:string;
    BuyerName:string;
}
