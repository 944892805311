import { PurchaseInvoiceDetails } from "./purchase-invoice-details.model";
import { TransactionPosting } from "../common/getjsonfrom-controls-service-model";
import { TotalProductTaxesModel } from "../tax-group/total-product-taxes.model";
import { UploadDocumentModel } from "../common/document-upload.model";

export class PurchaseInvoice
{
    InvoiceNumber: string;

    IsAutoNumbering: boolean;
    IsInserting: boolean;
    RowIndex?: number;
    Total?: number;
    InvoiceDate: string;

    GoDownCode: string;
    GoDownName: string;
    SalesAgentCode: string;

    VendorCode: string;
    VendorName: string;
    VendorAddress: string;

    OrderNumber: string;

    OrderDate: string;

    PaymentType: number;
    PaymentTypeName: string;


    CreditDays: number;

    CreditDateUpto: string;

    ReferenceNumber: string;

    Attention: string;

    GrandAmount: number;

    GlobalDiscountRate: number;

    GlobalDiscountAmount: number;

    NetAmount: number;

    AdvanceAmount: number;

    AmountPaid: number;

    AmountReturned: number;

    Instructions: any;

    GlobalTax1Code: string;
	GlobalTax2Code: string;
	GlobalTax1Name: string;
    GlobalTax2Name: string;
    
    GlobalTax1Rate: number;

    GlobalTax1Amount: number;

    GlobalTax2Rate: number;

    GlobalTax2Amount: number;   

    DirectExps1_Amount: number;

    DirectExps1_CurrencyCode: string;

    DirectExps1_CurrencyRate: number;

    DirectExps1_NetAmount: number;
    DirectExps1_FieldName: string;
    DirectExps2_Amount: number;
    DirectExps2_CurrencyCode: string;
    DirectExps2_CurrencyRate: number;
    DirectExps2_NetAmount: number;
    DirectExps2_FieldName: string;
    DirectExps3_Amount: number;

    DirectExps3_CurrencyCode: string;

    DirectExps3_CurrencyRate: number;

    DirectExps3_NetAmount: number;
    DirectExps3_FieldName: string;

    DirectExps4_Amount: number;

    DirectExps4_CurrencyCode: string;

    DirectExps4_CurrencyRate: number;

    DirectExps4_NetAmount: number;
    DirectExps4_FieldName: string;
    DirectExps5_Amount: number;

    DirectExps5_CurrencyCode: string;

    DirectExps5_CurrencyRate: number;

    DirectExps5_NetAmount: number;
    DirectExps5_FieldName: string;
    DirectExps6_Amount: number;

    DirectExps6_CurrencyCode: string;

    DirectExps6_CurrencyRate: number;

    DirectExps6_NetAmount: number;
    DirectExps6_FieldName: string;
    DirectExps7_Amount: number;

    DirectExps7_CurrencyCode: string;

    DirectExps7_CurrencyRate: number;

    DirectExps7_NetAmount: number;
    DirectExps7_FieldName: string;
    DirectExps8_Amount: number;

    DirectExps8_CurrencyCode: string;

    DirectExps8_CurrencyRate: number;

    DirectExps8_NetAmount: number;
    DirectExps8_FieldName: string;
    DirectExps9_Amount: number;

    DirectExps9_CurrencyCode: string;

    DirectExps9_CurrencyRate: number;

    DirectExps9_NetAmount: number;
    DirectExps9_FieldName: string;
    ProductDiscountTotal: number;

    IsAccountLinked: boolean;
    TransactionNumber: number;
    UpdationAllowed: boolean;
    PostedToAccount: boolean;

    GlobalTaxGroupID: string;
    GlobalTaxGroupName: string;
    IsGlobalTax1IncludedInPrice: boolean;

    IsGlobalTax2IncludedInPrice: boolean;
    

    ProductTax1IncludedInPrice: number;

    ProductTax2IncludedInPrice: number;
        
    VATNumber: string;

    IsTaxRefundable: boolean;

    IsTaxExempted: boolean;

    IsTaxByVendor: boolean;

    ProcessType: number;

    ProcessDocumentNumber: string;

    ExpensesTotal: number;
    PurchaseInvoiceDetails: PurchaseInvoiceDetails[];
    PurchaseInvoiceTaxes:TotalProductTaxesModel[];
    TransactionPosting: TransactionPosting[];
    DocumentsList:UploadDocumentModel[]
    IsBatchSerialLinked: boolean;

    CreditAlert: number; 
    SaleStatus: string;
    StatusCounts: string;

    GoodsReceivedReceiptID?: string;
}
