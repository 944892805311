
export class AccountCodeSetup {
    AutoCode: boolean;
    Level1: string;
    Level2: string;
    Level3: string;
    Level4: string;
    Level5: string;
    Level6: string;
    Level7: string;
    Level8: string;
    Level9: string;

    Level2Enable: boolean;
    Level3Enable: boolean;
    Level4Enable: boolean;
    Level5Enable: boolean;
    Level6Enable: boolean;
    Level7Enable: boolean;
    Level8Enable: boolean;
    Level9Enable: boolean;
    Level1Sample: string;
    Level2Sample: string;
    Level3Sample: string;
    Level4Sample: string;
    Level5Sample: string;
    Level6Sample: string;
    Level7Sample: string;
    Level8Sample: string;
    Level9Sample: string;

    UpdationAllowed: boolean;
    ImportAllowed: boolean;

}