import { StringLiteral } from "typescript";
import { StockBatchSerials } from "../common/getjsonfrom-controls-service-model";

export class SaleInvoiceDetails {
    SerialNumber: number;
    Name: string;
    ProductType: number;
    ProductCode: string;
    Quantity: number;
    UnitType: number;
    UnitCode: string;
    UnitFragment: number;
    UnitName: string;
    NetQuantity: number;
    Weight: number;
    NetWeight: number;
    Price: number;
    PriceType?: number;
    CurrencyCode?: string;
    CurrencyRate?: number;
    Amount: number;
    DiscountRate?: number;
    DiscountAmount?: number;
    Tax1Rate: number;
    Tax1Amount: number;
    Tax2Rate: number;
    Tax2Amount: number;
    Tax3Rate: number;
    Tax3Amount: number;
    Tax4Rate: number;
    Tax4Amount: number;
    GrandAmount?: number;
    GlobalDiscountAmount?: number;
    GlobalTax1Amount?: number;
    GlobalTax2Amount?: number;
    GlobalTax3Amount?: number;
    GlobalTax4Amount?: number;
    NetAmount: number;
    NetPrice: number;
    AddlChargesAmount: number;
    Description: string;
    ReturnedQuantity?: number;
    ReturnedWeight?: number;
    DeliveryNumber?: string;
    DeliveryDetailsID?: string;
    OrderNumber?: string;
    OrderDetailsID?: string;
    QuotationNumber?: string;
    QuotationDetailsID?: string;
    PreviousFiscalSpanID?: number;
    SalesPrice: number;
    CostPrice: number;
    CostAmount: number;
    ProductBarCode?: string;
    StockUpdationRequired?: boolean;
    GroupDataID?: string;
    Tax1Code: string;
    Tax2Code: string;
    Tax1Name: string;
    Tax2Name: string;
    GroupSerialNumber?: number;
    TaxGroupID: string;
    IsTax1IncludedInPrice: boolean;
    IsTax2IncludedInPrice: boolean;
    IsTax3IncludedInPrice: boolean;
    IsTax4IncludedInPrice: boolean;
    PriceOriginal?: number;
    Length: number;
    Width: number;
    Height: number;
    SubQuantity: number | null;
    GoDownCode: string;
    Action: string;
    OriginalQuantity: number;
    InvoiceDetailsID: string;
    IsMeasurementRequired: boolean;
    MeasurementType: number;
    IsBatchRequired: boolean;
    IsSerialNumberRequired: boolean;
    IsManufactureDateRequired: boolean;
    IsExpiryDateRequired: boolean;
    IsParentAddOn: boolean;
    AddOnGroupCode: string;
    ParentInvoiceDetailsID: string;
    SalesAgentCodePOS: string;
    BatchSerialDetailsInwards: StockBatchSerials[];
    BatchSerialDetails: StockBatchSerials[];
    url: string;
    IsLoyaltyProduct: boolean;
    DisableProductTypeDropDown: boolean;
    PurchaseInvoiceNumber: string;
    PurchaseInvoiceQuantity: number;
    
}
