﻿import { StockBatchSerials } from "../common/getjsonfrom-controls-service-model";

export class StockInternalUseDetail {

    ProductCode: string;
    Name: string;
    GoDownCode: string;
    ProductType: number;
    SerialNumber?: number;
    number?: number;
    UnitCode: string;
    UnitName: string;
    UnitFragment?: number;
    Quantity: number;
    Weight?: number;
    AdjustedQuantity?: number;
    Price?: number;
    PriceType?: number;
    Amount?: number;
    Description?: string;
    ProductBarCode?: string;
    Length?: number;
    Height?: number;
    Width?: number;
    SubQuantity?: number;
    Action: string;

    UnitType?: number;
    PriceOriginal?: number;
    TaxGroupID?: number;

    CurrentQuantity?: number;

    OriginalQuantity: number;
    StockInternalUseDetailsID: string;
    IsMeasurementRequired: boolean;
    MeasurementType: number;
    IsBatchRequired: boolean;
    IsSerialNumberRequired: boolean;
    IsManufactureDateRequired: boolean;
    IsExpiryDateRequired: boolean;
    BatchSerialDetailsInwards: StockBatchSerials[];
    BatchSerialDetails: StockBatchSerials[];
}
